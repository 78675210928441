export const ToastSuccessIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
      <path
        fill="#fff"
        d="M9 15l7-7-2-2-5 6-3-3-2 1 5 5zm1 5a10 10 0 01-7-3A10 10 0 011 6a10 10 0 019-6 10 10 0 017 3 10 10 0 012 11 10 10 0 01-9 6zm0-2l6-2 2-6-2-6-6-2-6 2-2 6 2 6 6 2z"
      ></path>
    </svg>
  );
};
