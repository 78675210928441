'use client';

import { useEffect, useState } from 'react';

export default function Staging() {
  const [staging, setStaging] = useState(false);
  const isBrowser = () => typeof window !== 'undefined'; //The approach recommended by Next.js

  useEffect(() => {
    if (!isBrowser()) {
      return;
    }
    setStaging(window.location.hostname.startsWith('staging'));
  }, []);

  if (staging) {
    return <div className="fixed-label">Staging</div>;
  }
  return null;
}
